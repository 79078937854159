import React, { Component } from 'react';
import loadable from '@loadable/component';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';

import { pvOutOfStockModalView } from 'events/product';
import { track } from 'apis/amethyst';
import { toggleOosButton } from 'actions/productDetail';
import ProductUtils from 'helpers/ProductUtils';
import type { FormattedProductBundle, FormattedProductSizing } from 'reducers/detail/productDetail';
import type { ProductStyle } from 'types/cloudCatalog';
import type { SelectedSizing } from 'types/product';
import type { AppState } from 'types/app';

export const OutOfStockModal = loadable(() => import('components/productdetail/OutOfStockModal'));

interface OwnProps {
  detail: FormattedProductBundle;
  isProductNotifyOpen: boolean;
  onCloseProductNotifyMe: () => void;
  onOpenProductNotifyMe: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onStyleChange: (event: React.MouseEvent) => void;
  productId: string;
  productStyles: Record<string, ProductStyle>;
  selectedSizing: SelectedSizing;
  sizing: FormattedProductSizing;
  style: ProductStyle;
  suppressOOSModal: boolean;
  showUnifiedOOS: boolean;
  renderRecos?: () => React.ReactNode;
  isHydraCefiEnabled?: boolean;
}

interface State {
  visible: null | boolean;
}

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = OwnProps & PropsFromRedux;

/**
 * Lazy loading wrapper around zappos OOS Modal
 */
export class OutOfStockModalWrapper extends Component<Props, State> {
  state: State = {
    visible: null
  };

  componentDidUpdate(prevProps: Props) {
    const { selectedSizing: prevSelectedSizing, style: prevStyle } = prevProps;
    const { selectedSizing, style } = this.props;
    const isSizeChanged = prevSelectedSizing !== selectedSizing;
    const isStyleChanged = prevStyle.styleId !== style.styleId;
    if (isSizeChanged || isStyleChanged) {
      this.setState({ visible: null });
    }
  }

  closeModal = () => {
    this.setState({ visible: false });
    const { toggleOosButton, onCloseProductNotifyMe } = this.props;
    toggleOosButton(false);
    onCloseProductNotifyMe();
  };

  makeSelectedSizing = (sizeId?: string) => {
    const {
      selectedSizing,
      sizing: { dimensions }
    } = this.props;
    const sizingMap: SelectedSizing = {};

    dimensions.forEach(({ id, rank }) => {
      const dimId = `d${id}`;

      if (rank === '1') {
        sizingMap[dimId] = sizeId;
      } else {
        sizingMap[dimId] = selectedSizing[dimId];
      }
    });

    return sizingMap;
  };

  hasAlts = () => {
    const { selectedSizing, productStyles, sizing } = this.props;
    const { dimensions } = sizing;
    if (!dimensions.length) {
      return null;
    }
    const { id, rank } = dimensions[0]!;
    const dimId = `d${id}`;
    let currentSizedId;

    if (rank === '1') {
      currentSizedId = selectedSizing[dimId];
    }
    const { stockData } = sizing;
    const newSelectedSizing = this.makeSelectedSizing(currentSizedId);
    const colorsBySize = ProductUtils.getColorsBySize(stockData, newSelectedSizing);
    // Render sizing values only if there are available colors in stock
    if (colorsBySize.length) {
      // Find styleIds that have a color match with colorsBySize
      const matches = Object.keys(productStyles).filter(styleId => {
        const style = productStyles[styleId]!; // gets whole obj
        return colorsBySize.find(obj => obj.color === style.colorId && obj.onHand !== '0');
      });
      return matches.length;
    }
    return false;
  };

  render() {
    const { visible } = this.state;
    const {
      productId,
      selectedSizing,
      sizing,
      style: { colorId, styleId },
      isProductNotifyOpen,
      oosButtonClicked,
      suppressOOSModal,
      suppressOOSModalCefi,
      isHydraCefiEnabled
    } = this.props;
    const { dimensions } = sizing;
    const allDimensionsSelected = dimensions.every(dim => selectedSizing[`d${dim.id}`]);

    if (isHydraCefiEnabled && suppressOOSModalCefi) {
      return null;
    }

    if (suppressOOSModal) {
      return null;
    }

    if (!allDimensionsSelected) {
      return null;
    }

    const hasAlts = this.hasAlts();
    const stock = ProductUtils.getStockBySize(sizing.stockData, colorId, selectedSizing);

    let isVisible = false;
    if (oosButtonClicked) {
      isVisible = oosButtonClicked;
    } else if (visible !== null) {
      isVisible = visible && !isProductNotifyOpen;
    } else if ((hasAlts && !stock) || (!isProductNotifyOpen && !hasAlts)) {
      isVisible = !stock;
    }

    if (!isVisible) {
      return null;
    }

    const dimensionId = selectedSizing.d3;
    const selectedDim = sizing.valueIdToName;
    const dimensionLabel = dimensionId && selectedDim[dimensionId]?.value;
    track(() => [
      pvOutOfStockModalView,
      {
        productId,
        styleId,
        colorId,
        dimensionId,
        dimensionLabel,
        sourcePage: 'PRODUCT_PAGE'
      }
    ]);

    return <OutOfStockModal {...this.props} makeSelectedSizing={this.makeSelectedSizing} hasAlts={hasAlts} onClose={this.closeModal} />;
  }
}

export const mapDispatchToProps = {
  toggleOosButton
};
export const mapStateToProps = (state: AppState) => {
  const {
    oosButtonClicked,
    product: { suppressOOSModal, suppressOOSModalCefi }
  } = state;
  return {
    oosButtonClicked,
    suppressOOSModal,
    suppressOOSModalCefi
  };
};
const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(OutOfStockModalWrapper);
